<template>
  <div id="disinfectant">
    <el-dialog
      :title="disinfectantFormTitle"
      width="1200px"
      :visible.sync="disinfectantDialogVisible"
      :close-on-click-modal="false"
      @close="disinfectantDialogClose"
    >
      <div id="pdfDom">
        <el-form
          ref="disinfectantFormRef"
          :model="disinfectantForm"
          :rules="disinfectantFormRules"
          label-position="right"
          label-width="120px"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="名称" prop="name">
                <el-input
                  v-model="disinfectantForm.name"
                  placeholder="请输入名称"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="搭配方法" prop="matchingMethod">
                <el-input
                  v-model="disinfectantForm.matchingMethod"
                  placeholder="请输入搭配方法"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="配置日期" prop="configurationDate">
                <el-date-picker
                  v-model="disinfectantForm.configurationDate"
                  placeholder="请选择日期"
                  type="date"
                  value-format="yyyy-MM-dd HH:mm:ss"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="溶剂名称" prop="solventName">
                <el-input
                  v-model="disinfectantForm.solventName"
                  placeholder="请输入溶剂名称"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="生产厂家" prop="manufacturer">
                <el-input
                  v-model="disinfectantForm.manufacturer"
                  placeholder="请输入生产厂家"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="溶剂批号" prop="solventBatch">
                <el-input
                  v-model="disinfectantForm.solventBatch"
                  placeholder="请输入溶剂批号"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="溶剂浓度" prop="solventChroma">
                <el-input
                  v-model="disinfectantForm.solventChroma"
                  placeholder="请输入溶剂浓度"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="溶剂数量" prop="solventQuantity">
                <el-input
                  v-model="disinfectantForm.solventQuantity"
                  placeholder="请输入溶剂数量"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="加入纯化水量" prop="addWater">
                <el-input
                  v-model="disinfectantForm.addWater"
                  placeholder="请输入加入纯化水量"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="配置后溶液数量" prop="solutionQuantity">
                <el-input
                  v-model="disinfectantForm.solutionQuantity"
                  placeholder="请输入配置后溶液数量"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="有效期" prop="validPeriod">
                <el-date-picker
                  v-model="disinfectantForm.validPeriod"
                  placeholder="请选择日期"
                  type="date"
                  value-format="yyyy-MM-dd"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="配置人" prop="configurator">
                <el-input
                  v-model="disinfectantForm.configurator"
                  placeholder="请输入配置人"
                  clearable
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="复核人" prop="reviewer">
                <el-input
                  v-model="disinfectantForm.reviewer"
                  placeholder="请输入复核人"
                  clearable
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="disinfectantDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="disinfectantFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="disinfectantFormTitle === '工艺用水水质日常检测详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备编号">
        <el-input
          v-model="searchForm.deviceNo"
          placeholder="请输入设备编号"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="disinfectantPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="matchingMethod" label="搭配方法" />
      <el-table-column prop="configurationDate" label="配置日期" />
      <el-table-column prop="solventName" label="溶剂名称" />
      <el-table-column prop="manufacturer" label="生产厂家" />
      <el-table-column prop="solventBatch" label="溶剂批号" />
      <el-table-column prop="solventChroma" label="溶剂浓度" />
      <el-table-column prop="solventQuantity" label="溶剂数量" />
      <el-table-column prop="addWater" label="加入纯化水量" />
      <el-table-column prop="solutionQuantity" label="配置后溶液数量" />
      <el-table-column prop="validPeriod" label="有效期" />
      <el-table-column prop="configurator" label="配置人" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="disinfectantPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addDisinfectant,
  deleteDisinfectant,
  updateDisinfectant,
  selectDisinfectantInfo,
  selectDisinfectantList,
} from "@/api/quality/disinfectant";
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data() {
    return {
      disinfectantDialogVisible: false,
      disinfectantFormTitle: "",
      disinfectantForm: {
        id: "",
        name: "",
        matchingMethod: "",
        configurationDate: "",
        solventName: "",
        manufacturer: "",
        solventBatch: "",
        solventChroma: "",
        solventQuantity: "",
        addWater: "",
        solutionQuantity: "",
        validPeriod: "",
        configurator: "",
        reviewer: "",
      },
      disinfectantFormRules: {
        name: [
          {
            required: true,
            message: "名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      disinfectantPage: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceNo: "",
      },
    };
  },
  created() {
    selectDisinfectantList(this.searchForm).then((res) => {
      this.disinfectantPage = res;
    });
  },
  methods: {
    disinfectantDialogClose() {
      this.$refs.disinfectantFormRef.resetFields();
      this.disinfectantForm.id = "";
    },
    disinfectantFormSubmit() {
      if (this.disinfectantFormTitle === "工艺用水水质日常检测") {
        this.disinfectantDialogVisible = false;
        return;
      }
      this.$refs.disinfectantFormRef.validate(async (valid) => {
        if (valid) {
          if (this.disinfectantFormTitle === "新增工艺用水水质日常检测") {
            await addDisinfectant(this.disinfectantForm);
          } else if (
            this.disinfectantFormTitle === "修改工艺用水水质日常检测"
          ) {
            await updateDisinfectant(this.disinfectantForm);
          }
          this.disinfectantPage = await selectDisinfectantList(this.searchForm);
          this.disinfectantDialogVisible = false;
        }
      });
    },
    handleAdd() {
      this.disinfectantFormTitle = "新增工艺用水水质日常检测";
      this.disinfectantDialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteDisinfectant(row.id);
        if (
          this.disinfectantPage.list.length === 1 &&
          this.searchForm.pageNum > 1
        ) {
          this.searchForm.pageNum--;
        }
        this.disinfectantPage = await selectDisinfectantList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.disinfectantFormTitle = "修改工艺用水水质日常检测";
      this.disinfectantDialogVisible = true;
      this.selectDisinfectantById(row.id);
    },
    handleInfo(index, row) {
      this.disinfectantFormTitle = "工艺用水水质日常检测详情";
      this.disinfectantDialogVisible = true;
      this.selectDisinfectantById(row.id);
    },
    selectDisinfectantById(id) {
      selectDisinfectantInfo(id).then((res) => {
        this.disinfectantForm.id = res.id;
        this.disinfectantForm.name = res.name;
        this.disinfectantForm.matchingMethod = res.matchingMethod;
        this.disinfectantForm.configurationDate = res.configurationDate;
        this.disinfectantForm.solventName = res.solventName;
        this.disinfectantForm.manufacturer = res.manufacturer;
        this.disinfectantForm.solventBatch = res.solventBatch;
        this.disinfectantForm.solventChroma = res.solventChroma;
        this.disinfectantForm.solventQuantity = res.solventQuantity;
        this.disinfectantForm.addWater = res.addWater;
        this.disinfectantForm.solutionQuantity = res.solutionQuantity;
        this.disinfectantForm.validPeriod = res.validPeriod;
        this.disinfectantForm.configurator = res.configurator;
        this.disinfectantForm.reviewer = res.reviewer;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      selectDisinfectantList(this.searchForm).then((res) => {
        this.disinfectantPage = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      selectDisinfectantList(this.searchForm).then((res) => {
        this.disinfectantPage = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      selectDisinfectantList(this.searchForm).then((res) => {
        this.disinfectantPage = res;
      });
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `工艺用水水质日常检测${this.disinfectantForm.configurationDate.substring(0, 10)}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  },
};
</script>

<style>
</style>
